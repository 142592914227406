import React, {} from 'react';
import './Header.css';
import { RouterButton,RouterIcon } from '../../RouterDom/RouterDom';
import { ReactComponent as Logo } from "../../../LocalAssets/VZNXWHITE.svg";

function Header() {
  return (
    <div className="HeaderSection">
      <div className='Header'>
        <RouterIcon to="/" Icon={Logo} className="BigIconButton"/>
        <div className='NavigationButtons'>
          <RouterButton label="About" to="/about" className="b3"/>
          <RouterButton label="Projects" to="/projects" className="b3"/>
          <RouterButton label="Services" to="/services" className="b3"/>
          <RouterButton label="Get in touch" to="/contact" className="b1"/>
        </div>
      </div>
    </div>
  );
}
export default Header;