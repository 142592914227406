import React, { useState } from 'react';
import './Services.css';

import { Link } from 'react-router-dom';

function Services() {


  return (
    <div className="AppSection">
      <div className='AboutTextBlock'>
        <h3>Live Visuals</h3>
        <p className='p1'>We specialize in crafting live and interactive visuals that transform events and installations. By using the latest industry tools, we amplify the experience and align with your creative direction, making every moment unforgettable.</p>
        <h3>Custom content</h3>
        <p className='p1'>Our team produces high-quality video and static content, tailored specifically for artists and shows. Whether for marketing or live events, we focus on delivering top-tier content that leverages the newest technology to make your vision come to life.</p>
        <h3>Show creation</h3>
        <p className='p1'>We collaborate closely with suppliers and show designers to conceptualize and execute show designs that are both realistic and spectacular. While we keep budget considerations in mind, our goal is to maximize the impact and creativity of your show, ensuring it exceeds expectations.</p>
      
      </div>
    </div>
  );
}

export default Services;
