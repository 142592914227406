import { useState, useEffect } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { db } from './FirebaseConfig.js';

const showFilters = [
  {
    videoSrc: "https://firebasestorage.googleapis.com/v0/b/website-a489a.appspot.com/o/Filters%2FFilterVideo-(Techno).mp4?alt=media&token=6561a91e-1368-4fe1-b76b-19c54f1b6556",
    overlaySrc: "https://firebasestorage.googleapis.com/v0/b/website-a489a.appspot.com/o/Filters%2FFilterOverlay-(Techno).png?alt=media&token=87a54b8d-c673-4051-b6ef-6ef172bf132a",
    altText: "Techno",
  },
  {
    videoSrc: "https://firebasestorage.googleapis.com/v0/b/website-a489a.appspot.com/o/Filters%2FFilterVideo-(BASS).mp4?alt=media&token=0011f533-0351-46f5-b243-08d1adbb4e5c",
    overlaySrc: "https://firebasestorage.googleapis.com/v0/b/website-a489a.appspot.com/o/Filters%2FFilterOverlay-(BASS).png?alt=media&token=fc460206-7895-4868-ade1-aa4e930bb8f3",
    altText: "Bass",
  },
  {
    videoSrc: "https://firebasestorage.googleapis.com/v0/b/website-a489a.appspot.com/o/Filters%2FFilterVideo-(HARD%20DANCE).mp4?alt=media&token=f9d3d92e-4028-47b6-89e0-90564e5f6362",
    overlaySrc: "https://firebasestorage.googleapis.com/v0/b/website-a489a.appspot.com/o/Filters%2FFilterOverlay-(HARD%20DANCE).png?alt=media&token=a47196f1-9b0e-4c13-8e67-82ad52ea757a",
    altText: "Hard Dance",
  },
  {
    videoSrc: "https://firebasestorage.googleapis.com/v0/b/website-a489a.appspot.com/o/Filters%2FFilterVideo-(LATIN).mp4?alt=media&token=1fcbba77-5dad-4de0-8bbe-1c5471a1c690",
    overlaySrc: "https://firebasestorage.googleapis.com/v0/b/website-a489a.appspot.com/o/Filters%2FFilterOverlay-(LATIN).png?alt=media&token=224f98f3-565b-4982-bf7c-2152e3605951",
    altText: "Latin",
  },
  {
    videoSrc: "https://firebasestorage.googleapis.com/v0/b/website-a489a.appspot.com/o/Filters%2FFilterVideo-(POP).mp4?alt=media&token=4c45f961-e570-4187-82d3-52b47a909dca",
    overlaySrc: "https://firebasestorage.googleapis.com/v0/b/website-a489a.appspot.com/o/Filters%2FFilterOverlay-(POP).png?alt=media&token=4bf27375-739a-40c2-ae90-3e7e7f8d021a",
    altText: "Pop",
  },
  {
    videoSrc: "https://firebasestorage.googleapis.com/v0/b/website-a489a.appspot.com/o/Filters%2FFilterVideo-(VISUAL%20ART).mp4?alt=media&token=adc93559-d2e9-46bf-bbb7-0f82d2521cb2",
    overlaySrc: "https://firebasestorage.googleapis.com/v0/b/website-a489a.appspot.com/o/Filters%2FFilterOverlay-(VISUAL%20ART).png?alt=media&token=1cc483b9-dce1-425d-957b-d906d3bd20c7",
    altText: "Visual Art",
  },
];

const formatNumberToText = (num) => {
  if (num >= 1_000_000_000) {
    return (num / 1_000_000_000).toFixed(1) + ' billion';
  } else if (num >= 1_000_000) {
    return (num / 1_000_000).toFixed(1) + ' million';
  } else if (num >= 1_000) {
    return (num / 1_000).toFixed(0) + ' thousand';
  }
  return num.toString();
};

export const GetContextData = () => {
  const [shows, setShows] = useState([]);
  const [artworks, setArtworks] = useState([]);
  const [highlightShows, setHighlightShows] = useState([]);
  const [totalAttendance, setTotalAttendance] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  console.log(highlightShows)

  useEffect(() => {
    const fetchShowsData = async () => {
      try {
        const showsCollection = collection(db, "Shows");
        const querySnapshot = await getDocs(showsCollection);
        const data = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
  
        const sortedByDate = data.sort((a, b) => {
          const dateA = a.Date?.toDate();
          const dateB = b.Date?.toDate();
          return dateB - dateA;
        });
  
        const top10Shows = sortedByDate
          .slice(0, 10)
          .sort((a, b) => b.Attendance - a.Attendance);
  
        setHighlightShows(top10Shows);
  
        const total = data.reduce((acc, show) => acc + (Number(show.Attendance) || 0), 0);
        const formattedTotal = formatNumberToText(total);
        setTotalAttendance(formattedTotal);
  
        setShows(sortedByDate);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };
  
    fetchShowsData();
  }, []);
  
  
  
  


  useEffect(() => {
    const fetchArtworkData = async () => {
      try {
        const artworkCollection = collection(db, "Art");
        const querySnapshot = await getDocs(artworkCollection);
        const data = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setArtworks(data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };
    fetchArtworkData();
  }, []);

  return { shows, highlightShows, totalAttendance, loading, error, showFilters, artworks };
};
