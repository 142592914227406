import { Link } from 'react-router-dom';

export const RouterButton = ({ label, to, className }) => (
  <div className='fitsize'>
    <Link to={to}>
      <button className={className}>
        {label}
      </button>
    </Link>
  </div>
);

export const RouterIcon = ({ to, className, Icon }) => (
  <div className='fitsize'>
    <Link to={to}>
      <button className={className} >
        {Icon && <Icon />}
      </button>
    </Link>
  </div>
);
