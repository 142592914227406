import React, { useState,useContext } from 'react';
import './About.css';
import { Context } from '../../../App';

function About() {
  const { totalAttendance } = useContext(Context);

  return (
    <div className="AppSection">
      <div className='AboutTextBlock'>
        <h3>About us</h3>
        <p className='p1'>In Creativity We Trust, and we bring this belief to life by crafting visuals that captivate live audiences. Our designs have reached {totalAttendance} people, enhancing experiences at festivals, concerts, and other live events. We specialize in developing visual concepts that resonate deeply, leaving a lasting impact on those who witness them.</p>
        <p className='p1'>Operating from our base in the Netherlands, we work globally to build strong, long-term relationships with our clients. Our industry connections enable us to assemble the perfect team for each project, delivering tailored solutions that grow with your vision over time.</p>
        <p className='p1'>Collaboration is at the heart of everything we do. By working closely with our clients, we ensure that every visual design is meticulously crafted to elevate the overall experience. From concept creation to live execution, we are dedicated to making every project a success, pushing the boundaries of what’s possible in live entertainment.</p>
      </div>
    </div>
  );
}

export default About;
