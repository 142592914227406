import React, { useState } from 'react';
import './Privacy.css';

import { Link } from 'react-router-dom';

function Privacy() {


  return (
    <div className="AppSection">
      <div className='Privacy'>
      <h2>Privacy</h2>
      <div>
          <h3>1. Information Collection</h3>
          <div className='row indentone'><p className='p3w'>1.</p><p className='p3w'>Cookies: We use cookies to improve the functionality and performance of our website. Cookies are small text files stored in your browser that help us remember your preferences and optimize your experience.</p></div>
          <div className='row indentone'><p className='p3w'>2.</p><p className='p3w'>Browser Data: We store some data in your browser's memory to ensure the website runs smoothly and loads quickly. This data may include caching of images, stylesheets, and other resources needed to deliver content efficiently.</p></div>
          <div className='row indentone'><p className='p3w'>3.</p><p className='p3w'>Google Analytics: We use Google Analytics to gather generic information about how visitors use our site. This includes data such as your approximate location, browser type, device type, the pages you visit, and the frequency of your visits. This information helps us understand user behavior and improve our website’s design and functionality.</p></div>
        </div>
        <div>
          <h3>2. How We Use Your Information</h3>
          <div className='row indentone'><p className='p3w'>1.</p><p className='p3w'>The information we collect is used solely for the following purposes:</p></div>
          <div className='row indenttwo'><p className='p3w'>1.</p><p className='p3w'>To improve the speed and performance of our website.</p></div>
          <div className='row indenttwo'><p className='p3w'>2.</p><p className='p3w'>To analyze website traffic and user interactions through Google Analytics.</p></div>
          <div className='row indenttwo'><p className='p3w'>3.</p><p className='p3w'>To understand how visitors use our website so we can enhance their experience.</p></div>
        </div>
        <div>
          <h3>3. Cookies and Tracking Technologies</h3>
          <div className='row indentone'><p className='p3w'>1.</p><p className='p3w'>Cookies and similar tracking technologies are used on our website for the purposes mentioned above. By using our site, you consent to the use of cookies. You can manage your cookie preferences through your browser settings, but please note that disabling cookies may affect the functionality of our website.</p></div>
        </div>
        <div>
          <h3>4. Third-Party Services</h3>
          <div className='row indentone'><p className='p3w'>1.</p><p className='p3w'>We use Google Analytics, a third-party service that collects and processes data as described in its own privacy policy. Google Analytics provides us with aggregated data that helps us analyze website usage patterns. Google’s Privacy Policy is available at Google’s Privacy Policy.</p></div>
        </div>
        <div>
          <h3>5. Data Security</h3>
          <div className='row indentone'><p className='p3w'>1.</p><p className='p3w'>We are committed to protecting the data we collect. We implement industry-standard security measures to prevent unauthorized access to or misuse of your information. However, please be aware that no method of transmission over the internet or electronic storage is 100% secure.</p></div>
        </div>
        <div>
          <h3>6. Your Rights</h3>
          <div className='row indentone'><p className='p3w'>1.</p><p className='p3w'>Since we do not collect personal data, there is no personal information for you to access, correct, or delete. However, if you have any questions about how we use cookies or Google Analytics, feel free to contact us.</p></div>
        </div>
        <div>
          <h3>7. Changes to This Policy</h3>
          <div className='row indentone'><p className='p3w'>1.</p><p className='p3w'>We may update this Privacy Policy from time to time to reflect changes in our practices or legal obligations. Any changes will be posted on this page, and the "Last Updated" date will be revised accordingly.</p></div>
        </div>
        <div>
          <h3>8. Contact Us</h3>
          <div className='row indentone'><p className='p3w'>1.</p><p className='p3w'>If you have any questions about this Privacy Policy or our practices, please contact us at:</p></div>
        </div>
      </div>
    </div>
  );
}

export default Privacy;
