import { useContext, useState } from "react";
import { Context } from "../../App";
import './HighlightCarousel.css';
import "react-responsive-carousel/lib/styles/carousel.min.css";
var Carousel = require('react-responsive-carousel').Carousel;

function HighlightCarousel({ handleSelectProject }) {
  const { highlightShows } = useContext(Context);
  const [activeIndex, setActiveIndex] = useState(0);

  return (
    <div className="CarouselWrapper NoSelect">
      <Carousel 
        showThumbs={false} 
        autoPlay
        infiniteLoop 
        onChange={(index) => setActiveIndex(index)}
      >
        {highlightShows.map((show, index) => (
          <div className="Carousel" key={show.id}>
            <img 
              className={`CarouselCover ${index === activeIndex ? "animateCover" : ""}`} 
              src={show.ShowCover} 
              alt={`${show.Name}`}
            />
            <div className="ClickableArea" onClick={() => handleSelectProject(show, "Show")}>
              <img 
                className={`CarouselLogo ${index === activeIndex ? "animateLogo" : ""}`} 
                src={show.ShowLogoCenter} 
                alt={`${show.Name}`} 
              />
            </div>
          </div>
        ))}
      </Carousel>
    </div>
  );
}

export default HighlightCarousel;
