import React, { useState } from 'react';
import './Contact.css';


function Contact() {
  const handleInput = (e) => {
    const textarea = e.target;
    textarea.style.height = 'auto';
    textarea.style.height = `${textarea.scrollHeight}px`;
  };

  return (
    <div className="AppSection">
      <div className='Contact'>
        <div>
          <h3>Get in Touch</h3>
          <p className='p3w'>Interested in bringing your project to life with us? We’re excited to hear from you!</p>
        </div>
        <div className='HorizontalDevider'>
          <div className='WidthHalfLocation'>
            <h3>Rotterdam</h3>
            <a className='p3' href="mailto:info@vizionx.eu">info@vizionx.eu</a>
            <a className='p3' href="tel:+31642035015">+31 642 035 015</a>
            <p className='p3'>VAT: NL004097551B59 </p>
            <p className='p3'>COC: 85458139 </p>
          </div>
          <div className='WidthHalf'>

          </div>
        </div>
      </div>
      
    </div>
  );
}

export default Contact;
