import React, { useState } from 'react';
import './Home.css';

import { Link } from 'react-router-dom';

function Home() {


  return (
    <div className="AppSection">
      <video className="backgroundvideo" autoPlay loop muted>
        <source src="https://firebasestorage.googleapis.com/v0/b/website-a489a.appspot.com/o/Generic%2Fvideo%20texture%20002.mp4?alt=media&token=880a0c14-f2ba-4024-9885-91a68da0fa88" type="video/mp4" />
      </video>
      <div className='TitleSection NoSelect'>
        <h1>VIZIONX</h1>
        <p className='p1'>Visual Design Studio</p>
      </div>
    </div>
  );
}

export default Home;
