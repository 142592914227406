import React, {} from 'react';
import './Footer.css';
import { RouterButton } from '../../RouterDom/RouterDom';

function Footer() {
  return (
    <div className="FooterSection">
      <div className="Footer">
        <div className='FooterDataSection'>
          <div className="FooterCollumns">
            <h3>Let’s collaborate</h3>
            <RouterButton label="Get in touch" to="/contact" className="b4"/>
            <a className='fitsize' href="https://www.behance.net/sarah_klein" target="_blank" rel="noopener noreferrer">
              <button className='b4'>Behance</button>
            </a>
            <a className='fitsize' href="https://www.instagram.com/vizionx.eu/" target="_blank" rel="noopener noreferrer">
              <button className='b4'>Instagram</button>
            </a>
          </div>
          <div className="FooterCollumns">
          <h3>Rotterdam</h3>
          <a className='b4' href="mailto:info@vizionx.eu">info@vizionx.eu</a>
          <a className='b4' href="tel:+31642035015">+31 642 035 015</a>
          </div>
        </div>
        <div className='FooterLegalRow'>
          <p className='b4'>© 2024 VizionX</p>
          <RouterButton label="Legal" to="/Legal" className="b4"/>
          <RouterButton label="Privacy Policy" to="/Privacy" className="b4"/>
        </div>
      </div>
    </div>
  );
}
export default Footer;