import React, { useState, useContext } from 'react';
import { Context } from "../../../App";
import './Projects.css';
import HighlightCarousel from '../../Reusables/HighlightCarousel';
import ProjectFocus from './ProjectFocus';

function Projects() {
  const { showFilters, shows, artworks } = useContext(Context);
  const [selectedProject, setSelectedProject] = useState(null);
  const [selectedType, setSelectedType] = useState(null);
  const [isProjectFocusVisible, setProjectFocusVisible] = useState(false);
  const [selectedGenre, setSelectedGenre] = useState(null);

  const handleSelectProject = (project, type) => {
    setSelectedProject(project);
    setSelectedType(type);
    setProjectFocusVisible(true);
  };

  const handleCloseProjectFocus = () => {
    setProjectFocusVisible(false);
  };

  const handleFilterClick = (genre) => {
    setSelectedGenre(selectedGenre === genre ? null : genre);
  };

  const filteredShows = selectedGenre 
    ? shows.filter(show => show.Genre && show.Genre.includes(selectedGenre)) 
    : shows;

  return (
    <div className="AppSection">
      {isProjectFocusVisible && (
        <ProjectFocus 
          Details={selectedProject} 
          Type={selectedType} 
          close={handleCloseProjectFocus} 
        />
      )}
      {!isProjectFocusVisible && (
        <div className='Projects'>
          <HighlightCarousel handleSelectProject={handleSelectProject} />
          <div className='ProjectsTypeSection NoSelect'>
            <div className='ProjectsTypeSectionHeaderRow'>
              <p className='p3 ProjectsTypeSectionHeaderRowText'>Live Visual Performances</p>
              <div className='Whiteline'></div>
            </div>

            <div className='FilterRow'>
              {showFilters.map((filter, index) => (
                <div 
                  className={`FilterItem ${selectedGenre === filter.altText ? 'Active' : ''}`} 
                  key={index} 
                  onClick={() => handleFilterClick(filter.altText)}
                >
                  <div className='FilterItemContent'>
                    <video className={`FilterBackground ${selectedGenre === filter.altText ? `Active` : ``}`} src={filter.videoSrc} autoPlay loop muted />
                    <img className={`FilterOverlay ${selectedGenre === filter.altText ? `Active` : ``}`} src={filter.overlaySrc} alt={filter.altText} />
                  </div>
                </div>
              ))}
            </div>

            <div className='ThumbnailGrid'>
              {filteredShows.map((show, index) => (
                <div
                  className="Thumbnail"
                  key={index}
                  onClick={() => handleSelectProject(show, "Show")}
                >
                  <div className='ThumbnailItemContent'>
                    <img className="ThumbnailBackground" src={show.ShowCover} alt="Show Cover" />
                    <img className="ThumbnailOverlay" src={show.ShowLogo} alt="Show Logo" />
                  </div>
                </div>
              ))}
            </div>

            <div className='ProjectsTypeSectionHeaderRow'>
              <p className='p3 ProjectsTypeSectionHeaderRowText'>Custom Artwork</p>
              <div className='Whiteline'></div>
            </div>

            <div className='ThumbnailGrid'>
              {artworks.map((artwork, index) => (
                <div
                  className="Thumbnail"
                  key={index}
                  onClick={() => handleSelectProject(artwork, "Artwork")}
                >
                  <div className='ThumbnailItemContent'>
                    <img className="ThumbnailBackground" src={artwork.ArtCover} alt="Artwork Cover" />
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Projects;
