import React, {} from 'react';
import './Legal.css';
import { IoMdDownload } from "react-icons/io";
import pdfFile from '../../../LocalAssets/2024v2.pdf'

function Legal() {
  const handleDownload = () => {
    const link = document.createElement('a');
    link.href = pdfFile;
    link.download = "2024v2.pdf";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className="AppSection">
      <div className='Legal'>
      <h2>Legal</h2>
        <div>
          <p className='p3w'>Current Version of our general terms and conditions is version: 2024v2.</p>
          <p className='p3w'>The downloaded version is the only legaly binding terms and conditions for the services provided by Vizionx.</p>
        </div>
        <button className='b2' onClick={handleDownload}><IoMdDownload className='Icon'/>2024v2.pdf</button>
        <div>
          <h3>1. Definitions</h3>
          <div className='row indentone'><p className='p3w'>1.</p><p className='p3w'>VizionX: The party performing work and or services regarding live show production and media assets based on these general terms and conditions.</p></div>
          <div className='row indentone'><p className='p3w'>2.</p><p className='p3w'>The client: The natural person or legal entity whom VizionX performs work for or provides services to based on these general terms and conditions.</p></div>
          <div className='row indentone'><p className='p3w'>3.</p><p className='p3w'>Agreement: any agreement concluded between VizionX and the client.</p></div>
          <div className='row indentone'><p className='p3w'>4.</p><p className='p3w'>Work: All work specified in the contract confirmation in the widest sense of the word.</p></div>
        </div>
        <div>
          <h3>2. Applicability general terms and conditions</h3>
          <div className='row indentone'><p className='p3w'>1.</p><p className='p3w'>These general terms shall apply to any and all offers, quotations, agreements and work provided by VizionX.</p></div>
          <div className='row indentone'><p className='p3w'>2.</p><p className='p3w'>Derogations from and changes to these general terms and conditions shall only be binding in the event they are explicitly agreed upon by both parties in writing. These changes or derogations will only apply to that offer/quotation or agreement under which they were made.</p></div>
          <div className='row indentone'><p className='p3w'>3.</p><p className='p3w'>These general terms and conditions also apply to subsequent contracts with the client.</p></div>
        </div>
        <div>
          <h3>3. Quotations, prices and payment</h3>
          <div className='row indentone'><p className='p3w'>1.</p><p className='p3w'>Quotations issued by VizionX are completely non-binding and valid up to 14 days after their issue, unless expressly stated or agreed on in writing.</p></div>
          <div className='row indentone'><p className='p3w'>2.</p><p className='p3w'>Unless stated otherwise, all quotations are subject to price change.</p></div>
          <div className='row indentone'><p className='p3w'>3.</p><p className='p3w'>Unless stated otherwise, our prices are:</p></div>
          <div className='row indenttwo'><p className='p3w'>1.</p><p className='p3w'>Stated in Euros.</p></div>
          <div className='row indenttwo'><p className='p3w'>2.</p><p className='p3w'>Excluding Value added tax (VAT) and or other government levies.</p></div>
          <div className='row indenttwo'><p className='p3w'>3.</p><p className='p3w'>Excluding rights.</p></div>
          <div className='row indentone'><p className='p3w'>4.</p><p className='p3w'>VizionX applies a 14 day payment term to all its invoices, unless agreed otherwise in writing.</p></div>
          <div className='row indentone'><p className='p3w'>5.</p><p className='p3w'>Payment by the client shall take place without any rebate or setoff.</p></div>
          <div className='row indentone'><p className='p3w'>6.</p><p className='p3w'>VizionX shall have the right to immediately pass on its claim for an unpaid invoice to a third party for debt collection. Any extra (juridical) costs shall be passed on to the client.</p></div>
          <div className='row indentone'><p className='p3w'>7.</p><p className='p3w'>VizionX shall retain the right to suspend any work under concluded agreement until the moment the client has fulfilled their obligations to VizionX.</p></div>
        </div>
        <div>
          <h3>4. Conclusion of agreement</h3>
          <div className='row indentone'><p className='p3w'>1.</p><p className='p3w'>An agreement shall only be concluded if a quotation is signed and returned or the contract/work is confirmed by VizionX in writing.</p></div>
          <div className='row indentone'><p className='p3w'>2.</p><p className='p3w'>Any verbal or written adjustments made to the agreement shall only be binding if they have been confirmed by VizionX in writing.</p></div>
          <div className='row indentone'><p className='p3w'>3.</p><p className='p3w'>VizionX has the right to demand certainty from the client, at the time of entering the agreement, or after the agreement has been entered into, that all obligations shall be fulfilled before any (further) performance.</p></div>
          <div className='row indentone'><p className='p3w'>4.</p><p className='p3w'>VizionX has the right to have the work for a client performed by a third party.</p></div>
        </div>
        <div>
          <h3>5. Cancellation</h3>
          <div className='row indentone'><p className='p3w'>1.</p><p className='p3w'>In the event of a cancellation of the agreement due to causes on the part of the client, the client shall be obligated to pay all reasonable costs inquired by VizionX. And pay all financial consequences for VizionX due to the non performance of the agreement. The Client is obligated to pay the following amount in the event of a cancellation:</p></div>
          <div className='row indenttwo'><p className='p3w'>1.</p><p className='p3w'>25% of the net amount if the cancellation takes place between the 30th and 15th day before the performance date.</p></div>
          <div className='row indenttwo'><p className='p3w'>2.</p><p className='p3w'>50% of the net amount if the cancellation takes place between the 14th and 8th day before the performance date.</p></div>
          <div className='row indenttwo'><p className='p3w'>3.</p><p className='p3w'>75% of the net amount if the cancellation takes place between the 7th and 2nd day before the performance date.</p></div>
          <div className='row indenttwo'><p className='p3w'>4.</p><p className='p3w'>100% of the net amount if the cancellation takes place within 24 hours of the performance date.</p></div>
          <div className='row indenttwo'><p className='p3w'>5.</p><p className='p3w'>100% of any and all cancellation fees on 3rd party services and rentals.</p></div>
        </div>
        <div>
          <h3>6. Rights and duties of the client</h3>
          <div className='row indentone'><p className='p3w'>1.</p><p className='p3w'>The client is held to provide all data and documents required by VizionX, or of which the client knows they are required for the correct performance of the work.</p></div>
          <div className='row indentone'><p className='p3w'>2.</p><p className='p3w'>The client shall vouch for the correctness, completeness and reliability of the data and documents provided to VizionX, also if they originate from a 3rd party.</p></div>
          <div className='row indentone'><p className='p3w'>3.</p><p className='p3w'>The client shall vouch that the information carriers and electronic files or software provided to VizionX are free of viruses and defects, also if they originate from a 3rd party.</p></div>
          <div className='row indentone'><p className='p3w'>4.</p><p className='p3w'>If requested by the client in writing, the documents provided by the client shall be returned to the client.</p></div>
          <div className='row indentone'><p className='p3w'>5.</p><p className='p3w'>The client shall ensure that VizionX can start its work at the time agreed. If the work of VizionX can start later than agreed on part of the client’s side it’s the duty of the client to inform VizionX as soon as possible.</p></div>
          <div className='row indentone'><p className='p3w'>6.</p><p className='p3w'>Any damages for VizionX resulting from any delays as referred to in this paragraph shall be for the account of the client.</p></div>
          <div className='row indentone'><p className='p3w'>7.</p><p className='p3w'>If any requests made by VizionX that have been confirmed by the client, and these are in reality not fulfilled, VizionX holds the right to suspend all work until this has been resolved.</p></div>
          <div className='row indentone'><p className='p3w'>8.</p><p className='p3w'>The client must ensure the required permits/exemptions, BUMA rights, injunctions, as well as adequate insurance for loss and theft.</p></div>
        </div>
        <div>
          <h3>7. Terms</h3>
          <div className='row indentone'><p className='p3w'>1.</p><p className='p3w'>VizionX shall hold the right to perform the contract in parts, which may be invoiced separately.</p></div>
          <div className='row indentone'><p className='p3w'>2.</p><p className='p3w'>Exceeding the term shall not oblige VizionX to any compensation. The client may give VizionX written notice of default after repeated term exceedance, stating a last (reasonable) delivery period. After this the client shall have the right to terminate the agreement in writing, unless the cause of the term exceedance is not attributable to VizionX.</p></div>
        </div>
        <div>
          <h3>8. Suspension and termination</h3>
          <div className='row indentone'><p className='p3w'>1.</p><p className='p3w'>The client and VizionX may terminate the agreement in writing at all times, unless the parties have agreed in writing otherwise. If the work has a continuing nature, the agreement may only be terminated towards the end of the agreed contract time subject to a notice period of 3 months.</p></div>
          <div className='row indentone'><p className='p3w'>2.</p><p className='p3w'>Termination must take place in writing.</p></div>
          <div className='row indentone'><p className='p3w'>3.</p><p className='p3w'>VizionX has the right to suspend performance of the agreement until further notice or to terminate the agreement in part or in full in the event of any of the occurrence below. A notice or court intervention shall not be required for this, neither shall VizionX be held to any damages or guarantee.</p></div>
          <div className='row indenttwo'><p className='p3w'>1.</p><p className='p3w'>The client does not properly, timely and/or completely fulfill the obligations under the agreement(s) which was concluded with VizionX.</p></div>
          <div className='row indenttwo'><p className='p3w'>2.</p><p className='p3w'>There is serious doubt as to whether the client is able to fulfill the obligations under the agreement(s) with VizionX.</p></div>
          <div className='row indenttwo'><p className='p3w'>3.</p><p className='p3w'>Of a bankruptcy of the client, suspension of payment, debt restructuring or shutdown, liquidation or full or partial transfer of the company of the client.</p></div>
          <div className='row indentone'><p className='p3w'>4.</p><p className='p3w'>If the agreement is terminated prematurely by the client, VizionX shall be entitled to compensation for the resulting loss of capacity utilization that can be made plausible, unless there are facts and circumstances underlying the cancellation that may be attributed to VizionX. Furthermore, the client shall then be obliged to pay the invoices for work performed up to then.</p></div>
          <div className='row indentone'><p className='p3w'>5.</p><p className='p3w'>If the agreement is terminated prematurely by VizionX, where possible, they shall arrange for the transfer of work still to be performed to be transferred to third parties in consultation with the client, unless the cancellation is based on facts and circumstances that may be attributed to the client. Costs resulting from this will be charged to the client in that case.</p></div>
        </div>
        <div>
          <h3>9. Liability</h3>
          <div className='row indentone'><p className='p3w'>1.</p><p className='p3w'>Liability of VizionX as a result of mistakes made by VizionX, because the client provided VizionX with incorrect or incomplete information, shall be excluded. The client is then liable (itself) for the loss suffered, including any loss suffered by VizionX.</p></div>
          <div className='row indentone'><p className='p3w'>2.</p><p className='p3w'>VizionX shall not be liable for damage to or loss of documents during transport or dispatch by mail, regardless of whether the transport or dispatch takes place by or on behalf of the client, VizionX or a third party.</p></div>
          <div className='row indentone'><p className='p3w'>3.</p><p className='p3w'>In the event of verbal information and/or requests, VizionX shall not be liable for any damages resulting from misunderstandings or incorrectly or incompletely received information.</p></div>
          <div className='row indentone'><p className='p3w'>4.</p><p className='p3w'>VizionX shall not be liable for any loss suffered by the client or a third party for whatever reason and which is in whatever way related to the contract provided to VizionX, unless it was an intentional act or gross negligence on the part of VizionX.</p></div>
          <div className='row indentone'><p className='p3w'>5.</p><p className='p3w'>Liability of VizionX for losses suffered by a client or third party, which was caused by a third party or third parties engaged by VizionX shall be excluded, even in the case of wilful act or gross negligence on the part of this third party or these third parties.</p></div>
          <div className='row indentone'><p className='p3w'>6.</p><p className='p3w'>Any liability expires after one year from the date of the loss.</p></div>
        </div>
        <div>
          <h3>10. Intellectual property</h3>
          <div className='row indentone'><p className='p3w'>1.</p><p className='p3w'>VizionX reserves all rights regarding the designs, media assets and software which it uses or used as part of the agreement with the client.</p></div>
          <div className='row indentone'><p className='p3w'>2.</p><p className='p3w'>The client is expressly forbidden to multiply, disclose, exploit, or otherwise burden the work provided by VizionX, including computer programmes, system designs, way of working, advice and other intellectual property rights of VizionX, in the widest sense of the word, whether or not by engaging third parties, without having obtained permission from VizionX to do so.</p></div>
          <div className='row indentone'><p className='p3w'>3.</p><p className='p3w'>VizionX reserves the right to use the knowledge gained by performing the work for other purposes, to the extent no confidential information is disclosed to third parties.</p></div>
        </div>
        <div>
          <h3>11. Applicable law</h3>
          <div className='row indentone'><p className='p3w'>1.</p><p className='p3w'>All transactions and agreements to which these general terms and conditions apply and the ensuing legal relations shall exclusively be governed by Dutch law.</p></div>
        </div>
      </div>
    </div>
  );
}

export default Legal;
