import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';

const FirebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASECONFIG_APIKEY,
  authDomain: process.env.REACT_APP_FIREBASECONFIG_AUTHDOMAIN,
  projectId: process.env.REACT_APP_FIREBASECONFIG_PROJECTID,
  storageBucket: process.env.REACT_APP_FIREBASECONFIG_STORAGEBUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASECONFIG_MESSAGINGSENDERID,
  appId: process.env.REACT_APP_FIREBASECONFIG_APPID,
  measurementId: process.env.REACT_APP_FIREBASECONFIG_MEASUREMENTID,
};

const app = initializeApp(FirebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);

export { db, auth };