import React, { createContext } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import Home from './Components/Pages/Home/Home';
import About from './Components/Pages/About/About';
import Projects from './Components/Pages/Projects/Projects';
import Services from './Components/Pages/Services/Services';
import Contact from './Components/Pages/Contact/Contact';
import Legal from './Components/Pages/Legal/Legal';
import Privacy from './Components/Pages/Privacy/Privacy';
import Admin from './Components/Pages/Admin/Admin';
import Footer from './Components/Pages/Footer/Footer';
import Header from './Components/Pages/Header/Header';
import { GetContextData } from './Components/API/Get';

export const Context = createContext();

function App() {
  const { shows, highlightShows, totalAttendance, loading, error, showFilters, artworks } = GetContextData();
  return (
    <Context.Provider value={{ shows, highlightShows, totalAttendance, showFilters, artworks }}>
      <Router>
        <div className="App">
          <Header />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/About" element={<About />} />
            <Route path="/Projects" element={<Projects />} />
            <Route path="/Services" element={<Services />} />
            <Route path="/Contact" element={<Contact />} />
            <Route path="/Legal" element={<Legal />} />
            <Route path="/Privacy" element={<Privacy />} />
            <Route path ="/Admin" element={<Admin />}/>
          </Routes>
          <Footer />
        </div>
      </Router>
    </Context.Provider>
  );
}

export default App;
