import React, { useState, useContext, useEffect } from 'react';
import { auth } from '../../API/FirebaseConfig';
import { Context } from '../../../App';
import { GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import './Admin.css';
import { RiAdminLine } from "react-icons/ri";
import { CgRemoveR } from "react-icons/cg";


function Admin() {
  const { showFilters, shows, artworks } = useContext(Context);
  const [loginMessage, setLoginMessage] = useState("");
  const [isAdmin, setIsAdmin] = useState(false);
  const [imageData, setImageData] = useState({});
  const [actsInputs, setActsInputs] = useState({});
  const [genreInputs, setGenreInputs] = useState({});

  const extractFileName = (url) => {
    const pathParts = url.split('/o/');
    if (pathParts.length > 1) {
      const filenameWithToken = pathParts[1].split('?')[0];
      const decodedPath = decodeURIComponent(filenameWithToken);
      return decodedPath.split('/').pop();
    }
    return 'Unknown';
  };

  const loadImageDimensions = (url, showId, imageType) => {
    const img = new Image();
    img.src = url;

    img.onload = () => {
      const dimensions = { width: img.width, height: img.height };
      setImageData((prevData) => ({
        ...prevData,
        [showId]: {
          ...prevData[showId],
          [imageType]: {
            name: extractFileName(url),
            dimensions,
          },
        },
      }));
    };
  };

  useEffect(() => {
    if (shows && shows.length > 0) {
      shows.forEach((show) => {
        ['ShowCover', 'ShowLogo', 'ShowLogoCenter'].forEach((type) => {
          const url = show[type];
          if (url) {
            loadImageDimensions(url, show.id, type);
          }
        });
      });
    }
  }, [shows]);

  const handleLogin = async () => {
    const provider = new GoogleAuthProvider();
    try {
      const result = await signInWithPopup(auth, provider);
      const user = result.user;
      if (user.uid === process.env.REACT_APP_ADMIN_UID) {
        setLoginMessage("Successfully logged in as Admin");
        setIsAdmin(true);
      } else {
        setLoginMessage("Looks like you do not have access to this panel");
        setIsAdmin(false);
      }
    } catch (error) {
      console.error("Error during sign-in:", error);
      setLoginMessage("Login failed. Please try again.");
      setIsAdmin(false);
    }
  };

  const handleImageChange = async (event, showId, imageType) => {
    const file = event.target.files[0];
    if (file) {
      const img = new Image();
      img.src = URL.createObjectURL(file);

      img.onload = () => {
        const dimensions = { width: img.width, height: img.height };
        setImageData((prevData) => ({
          ...prevData,
          [showId]: {
            ...prevData[showId],
            [imageType]: {
              name: file.name,
              dimensions,
            },
          },
        }));
      };
    }
  };

  // Handlers for Acts
  const handleAddActsInput = (showId, inputText) => {
    if (inputText.trim()) {
      setActsInputs((prevInputs) => ({
        ...prevInputs,
        [showId]: [...(prevInputs[showId] || []), inputText],
      }));
    }
  };

  const handleRemoveActsInput = (showId, index) => {
    setActsInputs((prevInputs) => ({
      ...prevInputs,
      [showId]: prevInputs[showId].filter((_, i) => i !== index),
    }));
  };

  // Handlers for Tags
  const handleAddTagsInput = (showId, inputText) => {
    if (inputText.trim()) {
      setGenreInputs((prevInputs) => ({
        ...prevInputs,
        [showId]: [...(prevInputs[showId] || []), inputText],
      }));
    }
  };

  const handleRemoveTagsInput = (showId, index) => {
    setGenreInputs((prevInputs) => ({
      ...prevInputs,
      [showId]: prevInputs[showId].filter((_, i) => i !== index),
    }));
  };

  return (
    <div className='AppSection'>
      <div className='Admin'>
        <button className='b2' onClick={handleLogin}><RiAdminLine /> Login</button>
        {loginMessage && <p className="loginMessage">{loginMessage}</p>}
        
        {isAdmin && (
          <div className="adminPanel">
            <div className='ProjectsTypeSectionHeaderRow'>
              <p className='p3 ProjectsTypeSectionHeaderRowText'>Edit shows</p>
              <div className='Whiteline'></div>
            </div>  
            <div className='AdminShowList'>
              {shows.map((show, index) => (
                <div className="AdminShowBlock" key={index}>
                  <h3>{show.Name}</h3>
                  <div className='AdminShowBlockDetailsSection'>
                    <div className='AdminShowBlockDetailsBigInputs'>
                      <div className="AdminFormRow">
                        <div className="FormTitleRow">
                          <label className="p1 NoSelect">Title</label>
                          <p className="p4 NoSelect">required</p>
                        </div>
                        <input type="text" id="Title" name="Title" placeholder="Title" className='AdminFormTextInput'/>
                      </div>
                      <div className="AdminFormRow">
                        <div className="FormTitleRow">
                          <label className="p1 NoSelect">Description</label>
                          <p className="p4 NoSelect">required</p>
                        </div>
                        <input type="text" id="Description" name="Description" placeholder="Description" className='AdminFormTextInput'/>
                      </div>
                      <div className="AdminFormRow">
                        <div className="FormTitleRow">
                          <label className="p1 NoSelect">Attendance</label>
                          <p className="p4 NoSelect">required</p>
                        </div>
                        <input type="text" id="Attendance" name="Attendance" placeholder="Attendance" className='AdminFormTextInput'/>
                      </div>
                      <div className="AdminFormRow">
                        <div className="FormTitleRow">
                          <label className="p1 NoSelect">Location</label>
                          <p className="p4 NoSelect">required</p>
                        </div>
                        <input type="text" id="Location" name="Location" placeholder="Location" className='AdminFormTextInput'/>
                      </div>
                    </div>

                    <div className='AdminFormMultipleInputs'>
                      <label className="p1 NoSelect">Acts</label>
                      <input type="text" placeholder="Add an Act" className="AdminFormTextInput" 
                             onKeyDown={(e) => { if (e.key === 'Enter') { handleAddActsInput(show.id, e.target.value); e.target.value = ''; }}} />
                      <div className="SpecialInputsList">
                        {actsInputs[show.id]?.map((input, i) => (
                          <div key={i} className="SpecialInputRow">
                            <span>{input}</span>
                            <button className='b3' onClick={() => handleRemoveActsInput(show.id, i)}><CgRemoveR /></button>
                          </div>
                        ))}
                      </div>
                    </div>

                    <div className='AdminFormMultipleInputs'>
                      <label className="p1 NoSelect">Genres</label>
                      <input type="text" placeholder="Add a Genre" className="AdminFormTextInput"
                             onKeyDown={(e) => { if (e.key === 'Enter') { handleAddTagsInput(show.id, e.target.value); e.target.value = ''; }}} />
                      <div className="SpecialInputsList">
                        {genreInputs[show.id]?.map((input, i) => (
                          <div key={i} className="SpecialInputRow">
                            <span>{input}</span>
                            <button className='b3' onClick={() => handleRemoveTagsInput(show.id, i)}><CgRemoveR /></button>
                          </div>
                        ))}
                      </div>
                    </div>

                  </div>
                  <div className='AdminShowBlockImagesSection'>
                    {['ShowCover', 'ShowLogo', 'ShowLogoCenter'].map((type) => (
                      <div className='AdminShowBlockImagesWithDetails' key={type}>
                        <p className='p3w'>{type}</p>
                        <img className="AdminImage" src={show[type]} alt={`${type}`} />
                        <p className='p4'>{imageData[show.id]?.[type]?.name || 'N/A'}</p>
                        <p className='p4'>{imageData[show.id]?.[type]?.dimensions ? `${imageData[show.id][type].dimensions.width}*${imageData[show.id][type].dimensions.height} px`: 'N/A'}</p>
                        <button className='b2' onClick={() => document.getElementById(`${show.id}-${type}`).click()}>Replace</button>
                        <input type="file" id={`${show.id}-${type}`} style={{ display: 'none' }} onChange={(e) => handleImageChange(e, show.id, type)} accept="image/*"/>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Admin;
